import * as msal from "@azure/msal-browser";

import api from "./api";

export const msalConfig: msal.Configuration = {
    auth: {
        clientId: process.env.NEXT_PUBLIC_CLIENT_ID || "",
        authority: `https://login.microsoftonline.com/${process.env.NEXT_PUBLIC_TENANT_ID}`,
        redirectUri: process.env.NEXT_PUBLIC_FRONTEND_URL,
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
};

export const msalInstance = new msal.PublicClientApplication(msalConfig);
export const loginRequest = {
    scopes: ["User.Read"],
};

// event callback after loggein
msalInstance.addEventCallback((event: msal.EventMessage & { payload: any }) => {
    if (
        event.eventType === "msal:loginSuccess" ||
        event.eventType === "msal:acquireTokenSuccess"
    ) {
        const {
            payload: {
                accessToken,
                idToken,
                account: {
                    name,
                    username,
                    idTokenClaims: { roles },
                },
            },
        } = event;
        sessionStorage.setItem(
            "userStore",
            JSON.stringify({
                accessToken,
                idToken,
                name,
                username,
                role: roles,
            })
        );
        api.setToken(idToken);
        console.log("Login success", username);
    } else {
        //console.warn(`Login warn`);
    }
});

export const refreshToken = () => {
    try {
        console.log("refresh Token");
        const userData = JSON.parse(
            (typeof sessionStorage !== "undefined" &&
                sessionStorage.getItem("userStore")) ||
                "null"
        );
        if (!userData) return false;
        const accountMsal = msalInstance.getAccountByUsername(
            userData.username
        );
        if (accountMsal) {
            msalInstance
                .acquireTokenSilent({
                    account: accountMsal,
                    forceRefresh: true,
                    scopes: ["User.Read"],
                })
                .then((token) => {
                    const { idToken } = token as { idToken: string };
                    api.setToken(idToken);
                    userData.accessToken = token.idToken;
                    sessionStorage.setItem(
                        "userStore",
                        JSON.stringify(userData)
                    );
                });
        }
    } catch (e) {
        console.error({ message: "refresh token error", e });
    }

    return true;
};
